<template>
  <div>
    <v-system-bar>Dispensationen (Liste)</v-system-bar>
    <v-data-table
      :items="items"
      :headers="headers"
      hide-default-footer
      :items-per-page="-1"
      sort-by="student"
      item-key="id"
      :item-class="() => 'clickable'"
      @click:row="
        (el) => {
          $router.push({
            name: 'DispensationEdit',
            params: { id: el.id },
          });
        }
      "
    >
      <template v-slot:item.student="{ item }">
        <PersonItem small :value="item.student">
          <template v-slot:listitems="scope">
            <v-list-item
              @click="$emit('selectStudent', item.student.id), scope.close()"
            >
              <v-list-item-icon
                ><v-icon>mdi-account-remove</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title
                  >alle Dispensationen anzeigen</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template></PersonItem
        >
      </template>

      <template v-slot:item.startDate="{ item }">
        <DateValue :value="item.startDate" /><span v-if="item.startTime"
          >, {{ item.startTime }}</span
        >
      </template>
      <template v-slot:item.endDate="{ item }">
        <DateValue :value="item.endDate" /><span v-if="item.endTime"
          >, {{ item.endTime }}</span
        >
      </template>
      <template v-slot:item.physicalEducation="{ item }">
        <v-icon v-if="item.physicalEducation">mdi-run</v-icon>
      </template>
      <template v-slot:footer
        ><TableFooter
          :items="items"
          label="Dispensationen"
          labelSingular="Dispensation"
      /></template>
    </v-data-table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import TableFooter from "common/components/TableFooter.vue";
import { comparePeople } from "common/utils/people.js";

export default defineComponent({
  name: "ListComponent",
  components: { DateValue, PersonItem, TableFooter },
  props: ["items"],
  data() {
    return {
      headers: [
        {
          text: "Schüler:in",
          value: "student",
          sort: (a, b) => comparePeople(a, b),
        },
        {
          text: "von",
          value: "startDate",
        },
        {
          text: "bis",
          value: "endDate",
        },
        {
          text: "Grund",
          value: "description",
        },
        {
          text: "Sport",
          value: "physicalEducation",
        },
      ],
    };
  },
});
</script>
